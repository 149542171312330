import React from 'react';
import { Modal, Button, message, Upload } from 'antd';
import { Link } from 'react-router-dom';

import { _success, _setAxios, _getBase64, _getBase64Upload, _beforeUploadExcel } from '../../../lib/Helper';
import config from '../../../Config';
import { DownloadOutlined, InboxOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

class ImportFile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
            validateFile: false,
            fileList: []
        };
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleChangeFile = ({ fileList }) => {
        if (this.state.validateFile === true) {
            fileList.forEach((row, i) => {
                fileList[i].status = "done"
            });

            this.setState({
                fileList: fileList
            })
        } else {
            this.setState({
                fileList: null
            })
        }
    };

    beforeUploadFile = (file) => {
        if (!_beforeUploadExcel(file)) {
            this.setState({
                validateFile: false,
            })
            return false
        } else {
            this.setState({
                validateFile: true,
            })
        }
        return true
    };

    handleSubmit = async () => {
        this.setState({
            loading: true
        });

        let fileList = this.state.fileList
        let file = null
        if (fileList.length > 0) {
            if (fileList[0].url && (fileList[0].url.includes("https://") || fileList[0].url.includes("http://"))) {
            } else {
                file = await _getBase64(fileList[0].originFileObj)
                file = _getBase64Upload(file)
            }

            if (fileList[0].status === "error") {
                message.error('File yang Anda upload tidak sesuai!');
                return false
            }
        }

        _setAxios("siswa/import", "POST", {
            file: file
        }).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                setTimeout(function () {
                    window.location.href = '/log/import';
                }, 1000);
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    render() {

        return (
            <Modal
                title="Upload data peserta didik"
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Import</Button>
                </>}
            >
                <div>Silahkan unduh template excel dibawah ini.</div>
                <div><a href={`${config.assets_url}/template-import/Template-Upload-Peserta-Didik.xlsx`}><DownloadOutlined /> Template Excel (.xlsx)</a></div>
                <br />
                <div>Untuk menambahkan data peserta didik melalui import excel, pastikan file sudah terisi sesuai format yang telah disediakan.</div>
                <br />
                <div>
                    <Dragger
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        maxCount={1}
                        fileList={this.state.fileList}
                        onChange={this.handleChangeFile}
                        beforeUpload={this.beforeUploadFile}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Klik atau seret file ke area ini</p>
                        <p className="ant-upload-hint">File yang diperbolehkan untuk diunggah: .XLSX</p>
                        <p className="ant-upload-hint">File yang diperbolehkan maksimal 2MB</p>
                    </Dragger>
                </div>
                <div style={{ textAlign: "left" }}>Log import <Link to={`/log/import`}>klik disini</Link></div>

            </Modal>
        )
    }
}

export default ImportFile;
