import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, BackTop, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { TableList } from 'components/list';
import AuthRedirect from 'components/AuthRedirect';
import ModalForm from '../modal/Form';
import ModalDelete from '../modal/Delete';
import SearchTable from 'components/search-table/SearchTable';
import { PageHeader } from 'components/header';
import { SortingTableOnly } from 'components/sorting-table';
import columns from './Columns';
import useList from '../hooks/useList';

const sortFields = [{
  value: "id",
  label: "Tanggal Pembuatan",
}, {
  value: "nama",
  label: "Nama",
}];

const Pendidikan = ({ privilege, tkId }) => {
  const {
    table,
    formState,
    deleteState,
    fetchTable,
    fetchData,
    modalForm,
    modalDelete,
  } = useList(tkId);

  const access = privilege.access["/master/jenis-perizinan"];
  if (!access) return <AuthRedirect />;
  const action = access.action;

  return (
    <>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <PageHeader
          className="site-page-header"
          title="Pendidikan Formal"
          extra={[
            action.create && (
              <Button
                type='primary'
                onClick={() => modalForm(true)}
                icon={<PlusOutlined />}>
                Tambah
              </Button>
            ),
          ]} />
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={18} md={19}>
            <SearchTable table={table} fetch={fetchData} />
          </Col>
          <Col xs={24} sm={6} md={5}>
            <SortingTableOnly
              table={table}
              fetch={fetchData}
              sortFields={sortFields}
            />
          </Col>
          <Col xs={24}>
            <TableList
              state={{ table }}
              fetch={fetchData}
              fetchTable={fetchTable}
              columns={columns(action, modalForm, modalDelete)}
            />
          </Col>
        </Row>
      </div>

      {formState.visible && (
        <ModalForm
          data={formState.data}
          tkId={tkId}
          visible={formState.visible}
          hideModal={() => modalForm(false)}
          fetchTable={fetchTable}
        />
      )}
      {deleteState.visible && (
        <ModalDelete
          data={deleteState.data}
          visible={deleteState.visible}
          hideModal={() => modalDelete(false)}
          fetchTable={fetchTable}
        />
      )}
      <BackTop />
    </>

  );
};

const mapStateToProps = (state) => ({
  privilege: state.privilege,
});

export default connect(mapStateToProps)(Pendidikan);