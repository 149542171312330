import { _tableLogActivity } from "lib/Helper";
import Action from "./Action";
import { SettingOutlined } from "@ant-design/icons";
import { LabelYesNo } from "components/labels";
import { DownloadFile } from "components/download";

const columns = (action, modalForm, modalDelete) => [
  {
    title: 'No',
    dataIndex: 'rownum',
    width: '3%',
  },
  {
    title: 'Jenjang',
    dataIndex: 'jenjang',
  },
  {
    title: 'Nama Lembaga',
    dataIndex: 'nama',
  },
  {
    title: 'Status Lembaga',
    dataIndex: 'status',
  },
  {
    title: 'Tahun Masuk',
    dataIndex: 'tahun_masuk',
  },
  {
    title: 'Tahun Lulus',
    dataIndex: 'tahun_keluar',
  },
  {
    title: 'Gelar Akademik',
    render: (_, row) => row.gelar_akademik ? row.gelar_akademik : "-",
  },
  {
    title: 'Ijazah',
    dataIndex: 'ijazah',
    render: (_, row) => <DownloadFile file={row.file} main_path={row.main_path} />
  }, {
    title: <SettingOutlined />,
    align: "center",
    key: 'operation',
    width: '2%',
    render: (_, row) => <Action row={row} action={action} modalForm={modalForm} modalDelete={modalDelete} />,
  },
  {
    title: 'LogActivity',
    key: 'logactivity',
    width: '10%',
    render: (a, row) => _tableLogActivity(row),
  },
];

export default columns