import React from 'react';
import { connect } from 'react-redux'

import { Table, Row, Col, Button, Menu, Dropdown, Pagination } from 'antd';
import { SettingOutlined, DownCircleOutlined, EditOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { getParamTable } from '../../../../lib/Helper';

class Desktop extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    };
  }

  componentDidMount() {
    this.props.fetchTable()
  }

  render() {
    const { table } = this.props.state
    const action = this.props.access.action
    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Table
              columns={[
                {
                  title: 'No',
                  dataIndex: 'rownum',
                  width: '2%',
                }, {
                  title: 'Nama Ekstra',
                  dataIndex: 'nama',
                }, {
                  title: 'Tahun Ajaran',
                  dataIndex: 'tahun_ajaran',
                  align: 'center',
                }, {
                  title: 'Guru Internal',
                  dataIndex: 'nama_lengkap',
                  render: (a, row) => <>{(row.gelar_depan ? row.gelar_depan + " " : "") + (row.nama_lengkap ? row.nama_lengkap : "-") + (row.gelar_belakang ? " " + row.gelar_belakang : "")}<br/>NIP: {row.nip}</>
                }, {
                  title: 'Guru Eksternal',
                  render: (a, row) => row.tk_eksternal ? row.tk_eksternal : "-"
                }, {
                  title: 'Ruangan',
                  dataIndex: 'ruangan_nama',
                  render: (a, row) => row.ruangan_nama ? row.ruangan_nama : "-"
                }, {
                  title: 'Jumlah Siswa',
                  dataIndex: 'jml_anggota',
                  render: (a, row) => row.jml_anggota ? row.jml_anggota : 0
                }, {
                  title: 'Jadwal',
                  dataIndex: 'jadwal',
                }, {
                  title: <SettingOutlined />,
                  align: "center",
                  key: 'operation',
                  width: '2%',
                  render: (a, row) => (!action.detail && !action.update && !action.update ? "-" : <Dropdown trigger={['click']} overlay={<Menu>
                    {action.detail ? (<Menu.Item key={0} icon={<InfoCircleOutlined />}><Link to={`ekstrakurikuler/detail/${row.id}`}> Detail</Link></Menu.Item>) : ""}
                    {action.update ? (<Menu.Item key={1} onClick={this.props.modalForm.bind(this, true, row)} icon={<EditOutlined />}> Update</Menu.Item>) : ""}
                    {action.delete ? (<Menu.Item key={2} onClick={this.props.modalDelete.bind(this, true, row)} icon={<CloseCircleOutlined />}> Delete</Menu.Item>) : ""}
                  </Menu>}>
                    <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                  </Dropdown>),
                },
              ]}
              rowKey={record => record.id}
              dataSource={table.data}
              pagination={false}
              loading={table.loading}
              size="small"
            />
          </Col>
          <Col xs={24} className="table-pagination">
            <Pagination
              size="small"
              current={table.pagination.current}
              pageSize={table.pagination.pageSize}
              total={table.pagination.total}
              showTotal={() => `Total ${table.pagination.total} items`}
              showSizeChanger
              onChange={(current, pageSize) => {
                const pagination = { current: current, pageSize: pageSize, total: table.pagination.total }
                const params = getParamTable("change", table, null, null, pagination)
                this.props.fetch(params)
              }}
            />
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(Desktop);
