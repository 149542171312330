import React from 'react';
import { Row, Col, Modal, Button, Select, Form } from 'antd';

import { genderOptions, jenjangSekolahAsalOptions } from '../../../data/options';
import { SelectRombel, SelectSemester, SelectTahunAjaran } from 'components/select';

const { Option } = Select;

class Filter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
            ddl: {
                tahunAjaran: [],
                rombel: [],
            },
        };
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    render() {
        const { values } = this.props
        return (
            <Modal
                title="Filter Peserta Didik"
                open={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Tutup</Button>
                    <Button type='primary' onClick={this.props.setTableFilterFromTemp}>Terapkan</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.Item label="Tahun Ajaran">
                        <SelectTahunAjaran
                            value={this.props.values?.tahun_ajaran_id || null}
                            onChange={(val) => {
                                this.props.setTempTableFilter("tahun_ajaran_id", val ? val : null)
                                this.props.setTempTableFilter("semester_id", null)
                                this.props.setTempTableFilter("rombel_id", null)
                            }}
                        />

                    </Form.Item>
                    <Form.Item label="Semester">
                        <SelectSemester
                            allowClear
                            value={values.semester_id}
                            tahunAjaranId={values.tahun_ajaran_id}
                            disabled={!values.tahun_ajaran_id}
                            onChange={(val) => {
                                this.props.setTempTableFilter("semester_id", val || null);
                                this.props.setTempTableFilter("rombel_id", null);
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="Rombongan Belajar">
                        <SelectRombel
                            allowClear
                            value={values.rombel_id}
                            tahunAjaranId={values.tahun_ajaran_id}
                            semesterId={values.semester_id}
                            disabled={!values.tahun_ajaran_id || !values.semester_id}
                            onChange={(val) => {
                                this.props.setTempTableFilter("rombel_id", val || null);
                            }}
                        />
                    </Form.Item>

                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12}>
                            <Form.Item label="Jenis Kelamin">
                                <Select
                                    showSearch
                                    allowClear
                                    name="jenis_kelamin"
                                    placeholder="Jenis Kelamin"
                                    value={values.jenis_kelamin}
                                    onChange={(e, newValue) => {
                                        this.props.setTempTableFilter("jenis_kelamin", newValue ? newValue.value : null)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {genderOptions.map((row, i) => <Option key={i} value={row.value}>{row.value}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item label="Jenjang Sekolah Asal">
                                <Select
                                    showSearch
                                    allowClear
                                    name="jenjang_sekolah_asal"
                                    placeholder="Jenjang Sekolah Asal"
                                    value={values.jenjang_sekolah_asal}
                                    onChange={(e, newValue) => {
                                        this.props.setTempTableFilter("jenjang_sekolah_asal", newValue ? newValue.value : null)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {jenjangSekolahAsalOptions.map((row, i) => <Option key={i} value={row.value}>{row.value}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
}

export default Filter;
