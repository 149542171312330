import React from 'react';
import { Row, Col, Modal, Button, Divider, Form, Input } from 'antd';
import useForm from '../hooks/useForm';
import { SelectJenjangPendidikan, SelectPresenceType, SelectStatusLembaga } from 'components/select';
import { SwitchBasic } from 'components/switch';
import { Date, RangeYear } from 'components/datetime';
import { UploadFile } from 'components/upload-files';
const { TextArea } = Input;

const ModalForm = ({ tkId, data = {}, visible, hideModal, fetchTable }) => {
    const [form] = Form.useForm(); // Initialize form instance

    const {
        values,
        loading,
        handleSubmit,
    } = useForm(tkId, data, fetchTable, hideModal);

    return (
        <Modal
            maskClosable={false}
            keyboard={false}
            title="Form Pendidikan Formal"
            width={700}
            open={visible}
            onCancel={hideModal}
            closable={false}
            footer={null} // Remove footer from Modal
        >
            <Form
                form={form}
                name="basic"
                autoComplete="off"
                layout="vertical"
                onFinish={handleSubmit}
                loading={loading}
                initialValues={values}
            >
                <Row gutter={[16, 0]}>
                    <Col xs={14}>
                        <Form.Item
                            label="Jenjang"
                            name={"jenjang"}
                            rules={[{ required: true, message: 'Jenjang harus dipilih' }]}>
                            <SelectJenjangPendidikan allowClear />
                        </Form.Item>
                        <Form.Item
                            label="Nama Lembaga"
                            name="nama"
                            rules={[{ required: true, message: 'Nama lembaga harus diisi!' }]}>
                            <Input placeholder='Nama lembaga' />
                        </Form.Item>
                        <Form.Item
                            label="Status Lembaga"
                            name={"status"}
                            rules={[{ required: true, message: 'Status harus dipilih' }]}>
                            <SelectStatusLembaga allowClear />
                        </Form.Item>
                        <Form.Item
                            label="Alamat"
                            name={"alamat"}>
                            <TextArea />
                        </Form.Item>
                        <Form.Item
                            label="Periode"
                            extra="Tahun masuk & keluar wajib dipilih"
                            rules={[{ required: true, message: 'Periode harus diisi!' }]}
                            name={"periode"}>
                            <RangeYear />
                        </Form.Item>
                        <Form.Item
                            label="Indeks Prestasi/Rata-Rata"
                            name="indeks_prestasi"
                            rules={[
                                {
                                    validator: (_, value) =>
                                        value && !isNaN(value) && parseFloat(value) === Number(value)
                                            ? Promise.resolve()
                                            : Promise.reject(new Error('Harus berupa angka desimal!')),
                                },
                            ]}
                        >
                            <Input placeholder='0.00' />
                        </Form.Item>
                    </Col>
                    <Col xs={10}>
                        <Form.Item
                            label="Tanggal"
                            name="tgl_ijazah">
                            <Date />
                        </Form.Item>
                        <Form.Item
                            label="Nomor Ijazah"
                            name="nomor_ijazah">
                            <Input placeholder='Nomor ijazah' />
                        </Form.Item>
                        <Form.Item label="File" name="file" rules={[{ required: data.id ? false : true, message: 'File harus diisi!' }]}>
                            <UploadFile
                                isValidation={true}
                                type="jpg, png & pdf"
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Divider />
                        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
                            <Button onClick={hideModal} disabled={loading}>Batal</Button>
                            <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>Simpan</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ModalForm;