import React, { useEffect, useState } from "react";
import { _setAxios } from "../../../lib/Helper";
import { Autocomplete, TextField } from "@mui/material";

async function fetchList() {

  return await _setAxios(`tahun-ajaran/tahun`)
    .then(body => {
      return body.data?.data?.map(row => ({
        value: String(row.id), // Pastikan tipe sesuai
        label: row.tahun_ajaran,
      })) || [];
    })
    .catch(() => []);
}

const SelectTahunAjaran = ({ value, errors, ...props }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    fetchList().then(newOptions => {
      setOptions(newOptions);

      // Tambahkan opsi jika props.value tidak ditemukan
      if (value && !newOptions.find(opt => opt.value === value)) {
        setOptions([...newOptions, { value, label: "Loading..." }]);
      }
    });
  }, [value]);

  return (
    <Autocomplete
      {...props}
      value={options.find(option => option.value === value) || null}
      options={options}
      noOptionsText="Tidak ada data"
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label="Tahun Ajaran"
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          name="tahun_ajaran_id"
          type="text"
          helperText={errors?.tahun_ajaran_id ? errors.tahun_ajaran_id[0] : null}
          error={Boolean(errors?.tahun_ajaran_id)}
        />
      )}
    />
  );
};

export default SelectTahunAjaran;
