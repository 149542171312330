import React from 'react';
import { connect } from 'react-redux'

import { _setAxios, _success, _tableLogActivity, getParamTable, _validationFormMsg } from '../../lib/Helper';
import { schemaTugasTambahanNonSatminkal } from './schema';
import validate from 'validate.js';
import { pendidikanFormalJenjangOptions, tugasJenisAktivitasOptions, tugasJenisTugasPembelajaranNonSatminkalOptions, tugasJenisTugasPendidikanOptions } from '../../data/options';

import { Table, Row, Col, Divider, Button, Menu, Dropdown, Tooltip, Modal, List, Tag } from 'antd';
import { PageHeader } from 'components/header';
import { ClockCircleOutlined, SaveOutlined, EllipsisOutlined, PlusOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import SearchTable from 'components/search-table/SearchTable';
import { SortingTableOnly } from 'components/sorting-table';
import { SelectSemester, SelectTahunAjaran, SelectRombel } from 'components/select/material-ui';

const sortFields = [{
    value: "id",
    label: "Tanggal Pembuatan",
}]


class TugasTambahanNonSatminkal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},

            table: {
                loading: false,
                data: [],
                search: "",
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                },
                sorting: [],
                filtering: {
                    tipe: "Tugas Tambahan Non Satminkal",
                },
            },
            form: {
                visible: false,
                loading: false,
            },
            fileList: [],
            validateFile: false,

            tipe: "Tugas Tambahan Non Satminkal",
            ddl: {
                mataPelajaran: [],
                jenisTugas: [],
            },
        };
    }

    componentDidMount() {
        this.fetchTable()
        this.getMataPelajaranDDL()
    }

    fetchTable = () => {
        const { table } = this.state
        const params = getParamTable("default", table)
        this.fetch(params);
    }

    fetch = async (params = {}) => {
        this.setState({
            table: {
                ...params,
                loading: true
            }
        });
        _setAxios("tk/tugas/table/" + this.props.dataID, "POST", params).then(resp => {
            if (resp.status === true) {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                        data: resp.data.list,
                        pagination: {
                            ...params.pagination,
                            total: resp.data.info.total
                        }
                    }
                });
            } else {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                    }
                })
            }
        })
    };

    getMataPelajaranDDL = () => {
        _setAxios("mata-pelajaran", "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        mataPelajaran: resp.data.data
                    },
                })
            }
        })
    }

    handleChange = (e) => {
        const { values } = this.state;
        const { name, value } = e.target;

        this.setForm(values, name, value)
    };

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)

        if (name === "jenis_aktivitas") {
            let jenisTugas = []
            if (value === "Pembelajaran") {
                jenisTugas = tugasJenisTugasPembelajaranNonSatminkalOptions
            } else if (value === "Kependidikan") {
                jenisTugas = tugasJenisTugasPendidikanOptions
            }

            this.setState({
                ddl: {
                    ...this.state.ddl,
                    jenisTugas: jenisTugas
                },
            });
        }
    };

    setForm = (values, name, value) => {
        values[name] = value;

        const errors = validate(values, schemaTugasTambahanNonSatminkal);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return this.setValidate(values)
    }

    setValidate = (values) => {
        const errors = validate(values, schemaTugasTambahanNonSatminkal);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return errors
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    // CREATE START ------------------------------------------
    hideModalForm = (row) => {
        this.setState({
            form: {
                ...this.state.form,
                visible: false
            },
            values: {},
            errors: {}
        });
    };

    showModalForm = (row) => {
        const { privilege } = this.props
        if (row.id) {
            this.setState({
                form: {
                    ...this.state.form,
                    visible: true,
                },

                values: {
                    id: row.id ? row.id : null,
                    tipe: this.state.tipe,
                    rombel: row.rombel_nama ? row.rombel_nama : null,
                    tahun_ajaran_id: row.tahun_ajaran_id ? row.tahun_ajaran_id : privilege?.tahun_ajaran?.id,
                    semester_id: row.semester_id ? row.semester_id : privilege?.semester?.id,
                    jenjang_pendidikan: row.jenjang_pendidikan ? row.jenjang_pendidikan : null,
                    mata_pelajaran: row.mata_pelajaran_nama ? row.mata_pelajaran_nama : null,
                    nama_sekolah: row.nama_sekolah ? row.nama_sekolah : null,
                    npsn: row.npsn ? row.npsn : null,
                    jenis_aktivitas: row.jenis_aktivitas ? row.jenis_aktivitas : null,
                    jenis_tugas: row.jenis_tugas ? row.jenis_tugas : null,
                    jml_siswa: row.jml_siswa ? row.jml_siswa : null,
                    jtm: row.total_jtm ? row.total_jtm : null,
                }
            });
        } else {
            this.setState({
                form: {
                    ...this.state.form,
                    visible: true,
                },
            });
        }
    };

    onSubmitForm = async (e) => {
        e.preventDefault();
        const { values } = this.state

        //Validation 
        if (values.jenis_aktivitas === "Pembelajaran") {
            schemaTugasTambahanNonSatminkal.jenis_tugas = {
                presence: { allowEmpty: false, message: 'harus dipilih' },
            }
        }

        let validation = this.setValidate(values, schemaTugasTambahanNonSatminkal)
        if (validation !== undefined) {
            return _validationFormMsg(validation)
        }

        // Payload
        const param = {
            tipe: this.state.tipe,
            tahun_ajaran_id: values.tahun_ajaran_id ? values.tahun_ajaran_id : null,
            semester_id: values.semester_id ? values.semester_id : null,
            jenjang_pendidikan: values.jenjang_pendidikan ? values.jenjang_pendidikan : null,
            nama_sekolah: values.nama_sekolah ? values.nama_sekolah : null,
            npsn: values.npsn ? values.npsn : null,
            jenis_aktivitas: values.jenis_aktivitas ? values.jenis_aktivitas : null,
            jenis_tugas: values.jenis_tugas ? values.jenis_tugas : null,
            rombel: values.rombel ? values.rombel : null,
            mata_pelajaran: values.mata_pelajaran ? values.mata_pelajaran : null,
            jml_siswa: values.jml_siswa ? values.jml_siswa : null,
            jtm: values.jtm ? values.jtm : null,
        }

        if (param.jenis_tugas === "Guru BK") {
            param.rombel = null
            param.rombel_id = values.rombel_id ? values.rombel_id.id : null
        }

        if (param.jenis_aktivitas !== "Pembelajaran") {
            if (param.jenis_tugas !== "Guru mapel") {
                param.mata_pelajaran = null
            }
            param.rombel = null
            param.jml_siswa = null
        }

        // Define Network
        let method = "POST"
        let payload = [param]
        let id = this.props.dataID
        if (values.id) {
            method = "PUT"
            payload = param
            id = values.id
        }

        this.setState({
            form: {
                ...this.state.form,
                loading: true
            }
        });
        _setAxios("tk/tugas-utama/" + id, method, payload).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.hideModalForm()
                this.fetchTable()
            }

            this.setState({
                form: {
                    ...this.state.form,
                    loading: false
                }
            });
        })
    };

    // CREATE END ------------------------------------------


    onDelete = (row) => {
        Modal.confirm({
            title: 'Hapus Data',
            width: 470,
            icon: <ExclamationCircleOutlined />,
            content: <>Data akan dihapus dari list, Apakah Anda Yakin ?</>,
            cancelText: 'Batal',
            okText: 'Simpan',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    _setAxios("tk/tugas/" + row.id, "DELETE").then(resp => {
                        if (resp.status === true) {
                            _success('topRight', 'Success', resp.data.message)
                            this.fetchTable()
                        }
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 100);
                    })
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel: () => {
                console.log("No action to process")
            },
        });
    }

    render() {
        const { table, ddl, values, errors } = this.state;
        const modalForm = <Modal
            title="Form Tugas Tambahan Non Satminkal"
            open={this.state.form.visible}
            // width={700}
            onCancel={this.hideModalForm}
            footer={<>
                <Button onClick={this.hideModalForm}>Batal</Button>
                <Button type="primary" htmlType="submit" onClick={this.onSubmitForm} loading={this.state.form.loading} icon={<SaveOutlined />}>Simpan</Button>
            </>}
        >
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <SelectTahunAjaran
                        errors={errors}
                        value={values.tahun_ajaran_id || ""}
                        onChange={(e, val) => {
                            this.handleChangeSetValue("tahun_ajaran_id", val ? val.value : null)
                        }}
                    />
                </Col>
                <Col xs={24}>
                    <SelectSemester
                        errors={errors}
                        tahunAjaranId={values.tahun_ajaran_id}
                        value={values.semester_id || ""}
                        onChange={(e, val) => {
                            this.handleChangeSetValue("semester_id", val ? val.value : null)
                        }}
                    />
                </Col>
                <Col xs={24}>
                    <TextField
                        fullWidth
                        label="Nama Sekolah *"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="nama_sekolah"
                        type="text"
                        onChange={this.handleChange}
                        value={values.nama_sekolah || ''}
                        error={this.hasError('nama_sekolah')}
                        helperText={
                            this.hasError('nama_sekolah') ? errors.nama_sekolah[0] : null
                        }
                    />
                </Col>
                <Col xs={24}>
                    <TextField
                        fullWidth
                        label="NPSN"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="npsn"
                        type="text"
                        onChange={this.handleChange}
                        value={values.npsn || ''}
                        error={this.hasError('npsn')}
                        helperText={
                            this.hasError('npsn') ? errors.npsn[0] : null
                        }
                    />
                </Col>
                <Col xs={24}>
                    <Autocomplete
                        options={pendidikanFormalJenjangOptions}
                        renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Jenjang Pendidikan *"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="jenjang_pendidikan"
                            type="text"
                            error={this.hasError('jenjang_pendidikan')}
                            helperText={
                                this.hasError('jenjang_pendidikan') ? errors.jenjang_pendidikan[0] : null
                            }
                        />}
                        value={values.jenjang_pendidikan || null}
                        onChange={(e, newValue) => {
                            this.handleChangeSetValue("jenjang_pendidikan", newValue ? newValue.value : null)
                        }}
                    />
                </Col>
                <Col xs={24} sm={12}>
                    <Autocomplete
                        options={tugasJenisAktivitasOptions}
                        renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Jenis Aktivitas *"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="jenis_aktivitas"
                            type="text"
                            error={this.hasError('jenis_aktivitas')}
                            helperText={
                                this.hasError('jenis_aktivitas') ? errors.jenis_aktivitas[0] : null
                            }
                        />}
                        value={values.jenis_aktivitas || null}
                        onChange={(e, newValue) => {
                            this.handleChangeSetValue("jenis_aktivitas", newValue ? newValue.value : null)
                        }}
                    />
                </Col>
                {
                    values.jenis_aktivitas ? <Col xs={24} sm={12}>
                        <Autocomplete
                            options={ddl.jenisTugas}
                            renderInput={(params) => <TextField {...params}
                                fullWidth
                                label="Jenis Tugas *"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="jenis_tugas"
                                type="text"
                                error={this.hasError('jenis_tugas')}
                                helperText={
                                    this.hasError('jenis_tugas') ? errors.jenis_tugas[0] : null
                                }
                            />}
                            value={values.jenis_tugas || null}
                            onChange={(e, newValue) => {
                                this.handleChangeSetValue("jenis_tugas", newValue ? newValue.value : null)
                            }}
                        />
                    </Col> : ''
                }
                {
                    values.jenis_aktivitas === "Pembelajaran" ?
                        <>
                            {
                                values.jenis_tugas === "Guru mapel" ?
                                    <Col xs={24} sm={12}>
                                        <TextField
                                            fullWidth
                                            label="Mata Pelajaran"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="mata_pelajaran"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.mata_pelajaran || ''}
                                            error={this.hasError('mata_pelajaran')}
                                            helperText={
                                                this.hasError('mata_pelajaran') ? errors.mata_pelajaran[0] : null
                                            }
                                        />
                                    </Col> : ""
                            }
                            <Col xs={24} sm={12}>
                                {
                                    values.jenis_tugas === "Guru BK" ? <SelectRombel
                                        errors={errors}
                                        value={values.rombel_id || ""}
                                        tahunAjaranId={values?.tahun_ajaran_id}
                                        semesterId={values?.semester_id}
                                        onChange={(e, val) => {
                                            this.handleChangeSetValue("rombel_id", val ? val.value : null)
                                        }}
                                    /> :
                                        <TextField
                                            fullWidth
                                            label="Rombel"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="rombel"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.rombel || ''}
                                            error={this.hasError('rombel')}
                                            helperText={
                                                this.hasError('rombel') ? errors.rombel[0] : null
                                            }
                                        />
                                }

                            </Col>
                            <Col xs={12}>
                                <TextField
                                    fullWidth
                                    label="Jumlah Siswa"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="jml_siswa"
                                    type="text"
                                    onChange={this.handleChange}
                                    value={values.jml_siswa || ''}
                                    error={this.hasError('jml_siswa')}
                                    helperText={
                                        this.hasError('jml_siswa') ? errors.jml_siswa[0] : null
                                    }
                                />
                            </Col>
                        </>
                        : ""
                }
                <Col xs={12}>
                    <TextField
                        fullWidth
                        label="JTM"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="jtm"
                        type="text"
                        onChange={this.handleChange}
                        value={values.jtm || ''}
                        error={this.hasError('jtm')}
                        helperText={
                            this.hasError('jtm') ? errors.jtm[0] : null
                        }
                        InputProps={{
                            endAdornment: <InputAdornment position="end"><ClockCircleOutlined /></InputAdornment>,
                            min: 0, max: 100
                        }}
                    />
                </Col>
            </Row>
        </Modal>

        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title="Tugas Tambahan Non Satminkal"
                    extra={
                        <>
                            <Tooltip title="Tambah">
                                <Button type='primary' icon={<PlusOutlined />} onClick={this.showModalForm}> Tambah</Button>
                            </Tooltip>
                        </>
                    }

                />
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={18} md={19}>
                        <SearchTable table={table} fetch={this.fetch} />
                    </Col>
                    <Col xs={24} sm={6} md={5}>
                        <SortingTableOnly
                            table={table}
                            fetch={this.fetch}
                            sortFields={sortFields}
                        />
                    </Col>
                </Row>
                <Divider orientation="left"></Divider>
                <Row>
                    <Col span={24}>
                        <Table
                            columns={[
                                {
                                    title: 'No',
                                    dataIndex: 'rownum',
                                    width: '3%',
                                },
                                {
                                    title: 'Tahun Ajaran',
                                    dataIndex: 'tahun_ajaran_id',
                                    width: '20%',
                                    render: (a, row) => <List.Item style={{ padding: 0 }}>
                                        <List.Item.Meta
                                            title={<>{row.thn_awal}/{row.thn_akhir}</>}
                                            description={<Tag color={row.tipe_semester === "Genap" ? "blue" : "green"}> {row.tipe_semester}</Tag>}
                                        />
                                    </List.Item>,
                                },
                                {
                                    title: 'Nama Sekolah',
                                    dataIndex: 'nama_sekolah',
                                },
                                {
                                    title: 'Tugas',
                                    dataIndex: 'jenis_tugas',
                                },
                                {
                                    title: 'Jenjang Pendidikan',
                                    dataIndex: 'jenjang_pendidikan',
                                },
                                {
                                    title: 'Mata Pelajaran',
                                    dataIndex: 'mata_pelajaran_nama',
                                },
                                {
                                    title: 'Rombel',
                                    dataIndex: 'rombel_nama',
                                },
                                {
                                    title: 'Jumlah Siswa',
                                    dataIndex: 'jml_siswa',
                                },
                                {
                                    title: 'JTM',
                                    dataIndex: 'total_jtm',
                                },
                                {
                                    title: <EllipsisOutlined />,
                                    align: "center",
                                    key: 'operation',
                                    width: '2%',
                                    render: (a, row) => <Dropdown trigger={['click']} overlay={<Menu>
                                        <Menu.Item key={0} onClick={this.showModalForm.bind(this, row)}><EditOutlined /> Update</Menu.Item>
                                        <Menu.Item key={1} onClick={this.onDelete.bind(this, row)}><DeleteOutlined /> Delete</Menu.Item>
                                    </Menu>}>
                                        <div className="ant-dropdown-link tb-action">
                                            <Button type='primary'>Opsi</Button>
                                        </div>
                                    </Dropdown>,
                                },
                                {
                                    title: 'LogActivity',
                                    key: 'operation',
                                    width: '10%',
                                    render: (a, row) => _tableLogActivity(row),
                                },
                            ]}
                            rowKey={record => record.id}
                            dataSource={table.data}
                            pagination={table.pagination}
                            loading={table.loading}
                            onChange={(pagination) => {
                                const params = getParamTable("change", table, null, null, pagination)
                                this.fetch(params)
                            }}
                            size="small"
                        />
                    </Col>
                </Row>

                {modalForm}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(TugasTambahanNonSatminkal);
