// useForm.js
import { useState } from 'react';
import { _getDate, _setAxios, _success } from 'lib/Helper';
import dayjs from 'dayjs';

const useForm = (tkId, data, fetchTable, hideModal) => {
    const [values] = useState({
        ...data,
        file: null,
        periode: data?.tahun_masuk && data?.tahun_keluar ? [dayjs(data?.tahun_masuk), dayjs(data?.tahun_keluar)] : null,
        tgl_ijazah: data?.tgl_ijazah ? dayjs(data?.tgl_ijazah) : dayjs(),
    });

    const [loading, setLoading] = useState(false);

    const handleSubmit = (val) => {

        const tglIjazah = _getDate(val.tgl_ijazah)
        let startYear = null;
        let endYear = null;
        if(val.periode) {
            const periode = val.periode;
            startYear = periode[0]?.year();
            endYear = periode[1]?.year();
        }
  
        let params = {
            jenjang: val.jenjang || null,
            nama: val.nama || null,
            status: val.status || null,
            alamat: val.alamat || null,
            tahun_masuk: startYear,
            tahun_keluar: endYear,
            indeks_prestasi: val.indeks_prestasi || null,
            tgl_ijazah: tglIjazah || null,
            nomor_ijazah: val.nomor_ijazah ? val.nomor_ijazah : null,
            file: val?.file || null
        }

        let endpoint = "tk/pendidikan/"+tkId;
        let method = "POST";

        if (data?.id) { 
            endpoint = `tk/pendidikan/${data?.id}`;
            method = "PUT";
        }else{
            params = [params];
        }

        setLoading(true);
        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status) {
                _success('topRight', 'Success', resp.data.message)
                fetchTable(true);
                hideModal();
            }
            setLoading(false);
        }).catch(err => {
            setLoading(false);
        });
    };

    return {
        values,
        loading,
        handleSubmit,
    };
};

export default useForm;
