import React from 'react';
import { Select } from 'antd';
import { pendidikanFormalJenjangOptions } from 'data/options';

const SelectJenjangPendidikan = ({ ...props }) => {
  return (
    <Select
      placeholder="Pilih jenjang"
      style={{
        width: '100%',
      }}
      options={pendidikanFormalJenjangOptions}
      {...props}
    />
  );
};
export default SelectJenjangPendidikan;
