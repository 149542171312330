import { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { getParamTable, _setAxios } from 'lib/Helper';
import { useParams } from 'react-router-dom';

const initialTableState = () => ({
    loading_first: false,
    loading: false,
    data: [],
    search: "",
    pagination: {
        current: 1,
        pageSize: 10,
        total: null,
    },
    sorting: [],
    filtering: {
    },
});

const useList = (privilege, siswaId) => {
    const { tahun_ajaran, semester, access } = privilege
    const { action } = access["/kesiswaan/pelanggaran-siswa"] || {}
    const { id, rombel_id } = useParams();

    const [table, setTable] = useState({
        ...initialTableState(),
        filtering: {
            ...initialTableState().filtering,
            tahun_ajaran_id: tahun_ajaran?.id,
            semester_id: semester?.id
        },
    });
    const [formState, setFormState] = useState({ data: {}, visible: false });
    const [deleteState, setDeleteState] = useState({ visible: false, data: {} });
    const [detailState, setDetailState] = useState({});

    const fetchTable = (isNew = false) => {
        const params = getParamTable("default", table);
        fetchData(params, isNew);
    };

    const fetchData = useCallback(async (params = {}, isNew = false) => {
        updateTableLoadingState(isNew);
        params.pagination.current = isMobile ? (isNew ? 1 : params.pagination.current) : params.pagination.current;

        const resp = await _setAxios("pelanggaran-siswa/table-riwayat/" + id, "POST", params);
        if (resp.status) {
            setTimeout(() => {
                updateTableData(resp.data, params, isNew);
            }, 0);
        } else {
            resetTableLoadingState();
        }
    }, []);

    const updateTableLoadingState = (isNew) => {
        setTable(prev => ({
            ...prev,
            loading_first: isMobile ? isNew : false,
            loading: true
        }));
    };

    const resetTableLoadingState = () => {
        setTable(prev => ({ ...prev, loading_first: false, loading: false }));
    };

    const updateTableData = (data, params, isNew) => {
        const updatedData = isNew ? [] : params.data;
        const current = params.pagination.current
        const newCurrent = isMobile ? current + 1 : current;

        setTable(prev => ({
            ...prev,
            ...params,
            loading_first: false,
            loading: false,
            data: isMobile ? [...updatedData, ...data?.list] : data?.list,
            pagination: {
                ...prev.pagination,
                current: newCurrent,
                total: data.info.total,
            },
        }));
    };

    const modalForm = (visible = false, data = {}) => {
        setFormState({ visible, data });
    };

    const modalDelete = (visible = false, data = {}) => {
        setDeleteState({ visible, data });
    };

    async function getSiswaByID(siswaId, rombelId) {
        if (!siswaId || !rombelId) {
            setDetailState({})
        }
        return await _setAxios("pelanggaran-siswa/table-header/" + siswaId + "/" + rombelId, "GET", null).then(resp => {
            setDetailState(resp.data.data)
        })
    }
    
    
    useEffect(() => {
        getSiswaByID(id, rombel_id)
    }, [id, rombel_id]);

    return {
        action,
        table,
        formState,
        deleteState,
        detailState,
        fetchTable,
        fetchData,
        modalForm,
        modalDelete,
        getSiswaByID,
    };
};

export default useList;
