import React, { useEffect, useState } from "react";
import { _setAxios } from "../../../lib/Helper";
import { Autocomplete, TextField } from "@mui/material";

async function fetchList(tahunAjaranId, semesterId) {
  if (!tahunAjaranId || !semesterId) {
    return [];
  }

  return await _setAxios(`rombel/dropdown/${tahunAjaranId}/${semesterId}`)
    .then(body => {
      return body.data?.data?.map(row => ({
        value: String(row.id), // Pastikan tipe sesuai
        label: row.nama,
      })) || [];
    })
    .catch(() => []);
}

const SelectRombel = ({ value, tahunAjaranId, semesterId, errors, ...props }) => {
  const [options, setOptions] = useState([]);
  // const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchList(tahunAjaranId, semesterId).then(newOptions => {
      setOptions(newOptions);
    });
  }, [tahunAjaranId, semesterId, value]);

  // if (loading) return "Loading...";

  return (
    <Autocomplete
      {...props}
      disabled={!tahunAjaranId || !semesterId}
      value={options.find(option => option.value === value) || null}
      options={options}
      noOptionsText="Tidak ada data"
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label="Rombongan Belajar"
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          name="rombel_id"
          type="text"
          helperText={errors?.rombel_id ? errors.rombel_id[0] : null}
          error={Boolean(errors?.rombel_id)}
        />
      )}
    />
  );
};

export default SelectRombel;
