import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';

const DownloadFile = ({ file, main_path }) => {
    if (!file) return "-"

    return <Button
        size='small'
        type='link'
        icon={<DownloadOutlined />}
        style={{ fontSize: 11 }}
        onClick={() => window.open(main_path + file)}
    >Download</Button>
};
export default DownloadFile;
