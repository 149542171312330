import React from 'react';
import { Select } from 'antd';
import { pendidikanFormalStatusLembagaOptions } from 'data/options';

const SelectStatusLembaga = ({ ...props }) => {
  return (
    <Select
      placeholder="Pilih status lembaga"
      style={{
        width: '100%',
      }}
      options={pendidikanFormalStatusLembagaOptions}
      {...props}
    />
  );
};
export default SelectStatusLembaga;
