import React, { useEffect, useState } from "react";
import { _setAxios } from "../../../lib/Helper";
import { Autocomplete, TextField } from "@mui/material";

async function fetchList(tahunAjaranId) {
  if (!tahunAjaranId) {
    return [];
  }

  return await _setAxios("tahun-ajaran/semester/" + tahunAjaranId, "GET")
    .then(body => {
      return body.data?.data?.map(row => ({
        label: row.tipe_semester,
        value: row.id,
    })) || [];
    })
    .catch(() => []);
}

const SelectSemester = ({ value, tahunAjaranId, errors, ...props }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    fetchList(tahunAjaranId).then(newOptions => {
      setOptions(newOptions);
    });
  }, [tahunAjaranId, value]);

  return (
    <Autocomplete
      {...props}
      disabled={!tahunAjaranId}
      value={options.find(option => option.value === value) || null}
      options={options}
      noOptionsText="Tidak ada data"
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label="Semester"
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          name="semester_id"
          type="text"
          helperText={errors?.semester_id ? errors.semester_id[0] : null}
          error={Boolean(errors?.semester_id)}
        />
      )}
    />
  );
};

export default SelectSemester;
