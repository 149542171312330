import React from 'react';
import { connect } from 'react-redux'

import { isAuth, _setAxios, getParamTable, getParamTempTableFiltering } from '../../../lib/Helper';
import validate from 'validate.js';
import AuthRedirect from '../../../components/AuthRedirect'

import { Table, Row, Col, Divider, Popover, Button, Radio, Space, Input, Tooltip, Modal, Form, Breadcrumb, Dropdown, Menu } from 'antd';
import { PageHeader } from 'components/header';
import { InfoCircleOutlined, SearchOutlined, SortAscendingOutlined, FilterOutlined, DoubleRightOutlined, SettingOutlined, DownCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { SelectRombel, SelectSemester, SelectTahunAjaran } from 'components/select';


const tableOptions = {
    sorts: [{
        value: "ASC",
        label: "Ascending",
    }, {
        value: "DESC",
        label: "Descending",
    }],
    fields: [{
        value: "id",
        label: "Tanggal Pembuatan",
    }, {
        value: "nama",
        label: "Nama",
    }]
}
const schema = {}

class HasilTesPesertaDidik extends React.Component {
    constructor(props) {
        super(props)
        const { privilege } = props
        this.state = {
            values: {},
            errors: {},

            table: {
                loading: false,
                data: [],
                search: "",
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                },
                sorting: [],
                filtering: {
                    tahun_ajaran_id: privilege?.tahun_ajaran?.id,
                    semester_id: privilege?.semester?.id,
                    rombel_id: null
                },
            },
            filter: {
                visible: false,
                values: {
                    tahun_ajaran_id: privilege?.tahun_ajaran?.id,
                    semester_id: privilege?.semester?.id,
                },
            },
        };
    }

    componentDidMount() {
        this.fetchTable()
    }

    fetchTable = () => {
        const { table } = this.state
        const params = getParamTable("default", table)
        this.fetch(params);
    }

    fetch = async (params = {}) => {
        this.setState({
            table: {
                ...params,
                loading: true
            }
        });
        _setAxios("bk/hasil-tes-siswa/table", "POST", params).then(resp => {
            if (resp.status === true) {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                        data: resp.data.list,
                        pagination: {
                            ...params.pagination,
                            total: resp.data.info.total
                        }
                    }
                });
            } else {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                    }
                })
            }
        })
    };

    handleChange = (e) => {
        const { values } = this.state;
        const { name, value } = e.target;

        this.setForm(values, name, value)
    };

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        this.setValidate(values)
    }

    setValidate = (values) => {
        const errors = validate(values, schema);
        this.setState({
            values: values,
            errors: errors || {},
        });
        return errors
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    // FILTER START ------------------------------------------

    hideModalFilter = () => {
        this.setState({
            filter: {
                ...this.state.filter,
                visible: false
            },
        });
    };

    showModalFilter = () => {
        this.setState({
            filter: {
                ...this.state.filter,
                visible: true
            },
        });
    };

    setTempTableFilter = (name, value) => {
        let values = this.state.filter.values
        values[name] = value

        this.setState({
            filter: {
                ...this.state.filter,
                values: values
            }
        })
    };

    setTableFilterFromTemp = () => {
        const params = getParamTempTableFiltering(this.state.table, this.state.filter.values);
        this.fetch(params)
        this.hideModalFilter()
    };
    // FILTER END ------------------------------------------

    render() {
        const access = this.props.privilege.access["/bimbingan-konseling/hasil-tes"]
        const action = access.action

        if (!isAuth(this.props.privilege) || access === undefined) {
            return <AuthRedirect />
        }

        const { table, filter } = this.state;

        console.log("filter", filter);
        const sortComponent = <>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort_field", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].field : ""} name="sort_field">
                <Space direction="vertical">
                    {tableOptions.fields.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
            <Divider orientation="left"></Divider>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].sort : ""} name="sort_value">
                <Space direction="vertical">
                    {tableOptions.sorts.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
        </>

        const modalFilter = <Modal
            title="Filter Hasil Tes Peserta Didik"
            open={this.state.filter.visible}
            onCancel={this.hideModalFilter}
            footer={<>
                <Button onClick={this.hideModalFilter}>Tutup</Button>
                <Button type='primary' onClick={this.setTableFilterFromTemp}>Terapkan</Button>
            </>}
        >
            <Form
                name="basic"
                autoComplete="off"
                layout="vertical"
            >
                <Form.Item label="Tahun Ajaran">
                    <SelectTahunAjaran
                        allowClear
                        value={filter?.values?.tahun_ajaran_id}
                        onChange={(val) => {
                            this.setTempTableFilter("tahun_ajaran_id", val || null);
                            this.setTempTableFilter("semester_id", null);
                            this.setTempTableFilter("rombel_id", null);
                        }}
                    />
                </Form.Item>
                <Form.Item label="Semester">
                    <SelectSemester
                        allowClear
                        value={filter?.values?.semester_id}
                        tahunAjaranId={filter?.values?.tahun_ajaran_id}
                        disabled={!filter?.values?.tahun_ajaran_id}
                        onChange={(val) => {
                            this.setTempTableFilter("semester_id", val || null);
                            this.setTempTableFilter("rombel_id", null);
                        }}
                    />
                </Form.Item>
                <Form.Item label="Rombel">
                    <SelectRombel
                        allowClear
                        value={filter?.values?.rombel_id}
                        tahunAjaranId={filter?.values?.tahun_ajaran_id}
                        semesterId={filter?.values?.semester_id}
                        disabled={!filter?.values?.tahun_ajaran_id || !filter?.values?.semester_id}
                        onChange={(val) => {
                            this.setTempTableFilter("rombel_id", val || null);
                        }}
                    />
                </Form.Item>
            </Form>
        </Modal>

        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Bimbingan Konseling</Breadcrumb.Item>
                        <Breadcrumb.Item>Hasil Tes Peserta Didik</Breadcrumb.Item>
                    </Breadcrumb>}
                />

                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>

                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <PageHeader
                                className="site-page-header"
                                title="Hasil Tes Peserta Didik"
                            />
                        </Col>
                        <Col xs={24} sm={21}>
                            <Input
                                name="search"
                                className='search-table'
                                placeholder="Search..."
                                prefix={<SearchOutlined className="site-form-item-icon" />}
                                onChange={(e) => {
                                    const params = getParamTable("search", table, e.target.value)
                                    this.fetch(params)
                                }}
                                value={table.search || ""}
                                suffix={
                                    <Tooltip title="Cari berdasarkan nama">
                                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                    </Tooltip>
                                }
                            />
                        </Col>
                        <Col xs={24} sm={3}>
                            <Button.Group className='ant-btn-group-fullwidth'>
                                <Button className='btn-border-radius-left' onClick={this.showModalFilter}><FilterOutlined /></Button>
                                <Popover placement="bottom" content={sortComponent} trigger="click">
                                    <Button block className='btn-border-radius-right'><SortAscendingOutlined /> Sort</Button>
                                </Popover>
                            </Button.Group>
                        </Col>
                        <Col xs={24}>
                            <Divider />
                        </Col>
                        <Col span={24}>
                            <Table
                                columns={[
                                    {
                                        title: 'No',
                                        dataIndex: 'rownum',
                                        width: '3%',
                                    },
                                    {
                                        title: 'NISN',
                                        dataIndex: 'nisn',
                                    },
                                    {
                                        title: 'Nama Peserta Didik',
                                        dataIndex: 'nama',
                                    },
                                    {
                                        title: 'Jenis Kelamin',
                                        dataIndex: 'jenis_kelamin',
                                    },
                                    {
                                        title: 'Rombel',
                                        dataIndex: 'rombel_nama',
                                    },
                                    {
                                        title: 'Jumlah Tes Diambil',
                                        dataIndex: 'jml_tes',
                                    },
                                    {
                                        title: <SettingOutlined />,
                                        key: 'operation',
                                        align: 'center',
                                        width: '2%',
                                        render: (a, row) => <Dropdown trigger={['click']} overlay={<Menu>
                                            {action.detail ? (<Menu.Item key={0}><Link to={"/bimbingan-konseling/hasil-tes/detail/" + row.id + "/" + row.rombel_id}><InfoCircleOutlined /> Detail</Link></Menu.Item>) : ""}
                                        </Menu>}>
                                            <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                                        </Dropdown>,
                                    },
                                ]}
                                rowKey={record => record.id}
                                dataSource={table.data}
                                pagination={table.pagination}
                                loading={table.loading}
                                onChange={(pagination) => {
                                    const params = getParamTable("change", table, null, null, pagination)
                                    this.fetch(params)
                                }}
                                size="small"
                            />
                        </Col>
                    </Row>
                </div>

                {modalFilter}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(HasilTesPesertaDidik);
